import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import NotFound from "./NotFound";
import { CircleLoader } from "react-spinners";

function removeMarkdown(markdownText) {
  // If markdownText is null or undefined, return null
  if (!markdownText) return null;

  // Remove headers (#, ##, ...)
  let content = markdownText.replace(/#+\s+/g, "");

  // Remove * characters
  content = content.replace(/\*/g, "");

  // Remove quote characters (" and `)
  content = content.replace(/[`"]/g, "");

  // Remove brackets
  content = content.replace(/\[|\]/g, "");

  // Remove anything inside parentheses
  content = content.replace(/\([^)]*\)/g, "");

  // Remove starting parentheses and anything after it
  content = content.replace(/\(.*$/, "");

  // Replace newlines with spaces
  content = content.replace(/\n/g, " ");

  // Remove hyphens
  content = content.replace(/[-–]/g, " ");

  // Remove extra spaces
  content = content.replace(/\s+/g, " ");

  return content;
}

function BlogPost() {
  const { slug } = useParams();

  const [post, setPost] = useState(null);
  const [fetchFailed, setFetchFailed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const topStyle = windowWidth >= 1200 ? "8rem" : "5rem";

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BLOG_URL}/api/blogs/?filters[Slug][$eq]=${slug}&populate=*`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.data && res?.data?.length > 0) {
          setPost(res?.data?.[0]);
          setFetchFailed(false);
        } else {
          setFetchFailed(true);
        }
      });
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{"PhotoTag.ai - " + post?.attributes?.Title}</title>
        <meta
          name="description"
          content={removeMarkdown(post?.attributes?.Body?.substring(0, 700))}
        />
        <meta name="author" content={post?.attributes?.Author} />
        <meta property="og:title" content={post?.attributes?.Title} />
        <meta
          property="og:description"
          content={removeMarkdown(post?.attributes?.Body?.substring(0, 700))}
        />
        <meta
          property="og:image"
          content={post?.attributes?.Photo?.data?.attributes?.url}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="PhotoTag.ai" />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={post?.attributes?.Title} />
        <meta
          name="twitter:description"
          content={removeMarkdown(post?.attributes?.Body?.substring(0, 700))}
        />
        <meta
          name="twitter:image"
          content={post?.attributes?.Photo?.data?.attributes?.url}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              },
              "headline": "${post?.attributes?.Title}",
              "description": "${removeMarkdown(
                post?.attributes?.Body?.substring(0, 700)
              )}",
              "image": "${post?.attributes?.Photo?.data?.attributes?.url}",
              "author": {
                "@type": "Person",
                "name": "${post?.attributes?.Author}"
              },
              "publisher": {
                "@type": "Organization",
                "name": "PhotoTag.ai",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://phototag.ai/assets/logo.png"
                }
              },
              "datePublished": "${post?.attributes?.Date}",
              "dateModified": "${post?.attributes?.Date}"
            }
          `}
        </script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#f0f4f8",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: "1.5rem",
          paddingTop: fetchFailed ? undefined : "8rem",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Link
          to="/blog"
          style={{
            position: "fixed",
            top: topStyle,
            left: "1rem",
            zIndex: 1000,
          }}
        >
          <IconButton style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "1.5rem",
            height: "100%",
          }}
        >
          {fetchFailed ? (
            <NotFound />
          ) : post ? (
            <Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: { xs: "90vw", md: "80vw" },
                  margin: "2rem 0",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    maxWidth: { xs: "100%", md: "70%" },
                  }}
                >
                  {post?.attributes?.Title}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#000",
                    maxWidth: "80%",
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {post?.attributes?.Author}
                  </span>
                  {" • "}
                  {post?.attributes?.Date}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: "#000",
                  maxWidth: { xs: "90%", md: "60%" },
                  textAlign: "left",
                }}
              >
                <ReactMarkdown
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      return !inline ? (
                        <pre style={{ overflowX: "auto" }}>
                          <code className={className} {...props}>
                            {children}
                          </code>
                        </pre>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                  }}
                >
                  {post?.attributes?.Body}
                </ReactMarkdown>
              </Typography>
              <img
                src={`${post?.attributes?.Photo?.data?.attributes?.url}`}
                alt={post?.attributes?.Title}
                style={{
                  maxWidth: "35%",
                  borderRadius: "16px",
                  marginBottom: "2rem",
                }}
              />
            </Fragment>
          ) : (
            <CircleLoader color="#000" size={50} />
          )}
        </Box>
      </Box>
    </Fragment>
  );
}

export default BlogPost;
