import { readAndCompressImage } from "browser-image-resizer";
import { useTranslation } from "react-i18next";
import { Fragment, useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ModalItem from "./modalItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDropzone } from "react-dropzone";
import { zip } from "fflate";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadFiles = ({
  albumData,
  albumName,
  albumLoading,
  addMetadata,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [reduceFileSize, setReduceFileSize] = useState(false);
  const [batchPhotos, setBatchPhotos] = useState(false);

  useEffect(() => {
    const batchPhotosToggle = localStorage.getItem("batchPhotos");
    if (batchPhotosToggle) {
      setBatchPhotos(batchPhotosToggle === "show");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("batchPhotos", batchPhotos ? "show" : "hide");
  }, [batchPhotos]);

  useEffect(() => {
    const reduceFileSizeToggle = localStorage.getItem("reduceFileSize");
    if (reduceFileSizeToggle) {
      setReduceFileSize(reduceFileSizeToggle === "show");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("reduceFileSize", reduceFileSize ? "show" : "hide");
  }, [reduceFileSize]);

  useEffect(() => {
    setFiles([]);
  }, [reduceFileSize]);

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 1500) {
      setError(t("export.tooManyFiles"));
      return;
    }

    const largeFiles = acceptedFiles.filter(
      (file) => file.size > 100 * 1024 * 1024
    );
    if (largeFiles.length > 0) {
      setError(t("export.largeFiles"));
      return;
    }

    const filteredFiles = acceptedFiles.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp" ||
        file.type === "video/mp4" ||
        file.type === "video/quicktime"
    );

    if (filteredFiles.length === 0) {
      setError(t("export.unsupportedFiles"));
      return;
    } else if (filteredFiles.length !== acceptedFiles.length) {
      setError(t("export.skippingUnsupportedFiles"));
    }

    if (reduceFileSize) {
      setUploading(true);

      const imageConfig = {
        quality: 0.8,
        maxWidth: 1920,
        maxHeight: 1080,
        autoRotate: true,
      };

      const batchSize = 5;
      const compressedFiles = [];
      for (let i = 0; i < filteredFiles.length; i += batchSize) {
        const batch = filteredFiles.slice(i, i + batchSize);
        const compressedBatch = await Promise.all(
          batch.map(async (file) => {
            if (file.type.startsWith("video/")) {
              return file;
            }
            try {
              const compressedBlob = await readAndCompressImage(
                file,
                imageConfig
              );
              return new File([compressedBlob], file.name, { type: file.type });
            } catch (error) {
              return file;
            }
          })
        );
        compressedFiles.push(...compressedBatch);
      }
      setFiles(compressedFiles);

      setUploading(false);
    } else {
      setFiles(filteredFiles);
    }
  };

  const getMetadata = useCallback(
    (name) => {
      if (!albumData) return null;
      return albumData.find((item) => item.name === name);
    },
    [albumData]
  );

  const processFilesInBatches = useCallback(async () => {
    setLoading(true);
    setProgress(0);

    const batchSize = 5;
    const zipBatchSize = batchSize * 5;
    const totalFiles = files.length;
    const totalZipBatches = Math.ceil(totalFiles / zipBatchSize);

    let zipFiles = {};
    let zipFileCount = 0;
    let atLeastOneFileZippedTotal = false;

    for (let i = 0; i < totalFiles; i += batchSize) {
      const batch = files.slice(i, i + batchSize);
      const currentBatchIndex = Math.floor(i / batchSize);
      const totalProcessedFiles = currentBatchIndex * batchSize;
      const currentZipBatchIndex = Math.floor(i / zipBatchSize);
      const lastBatch = i + batchSize >= totalFiles;

      let currentZipFiles = {};
      let processedFiles = 0;
      let atLeastOneFileZipped = false;
      let atLeastOneFileError = false;
      let missingFileWarning = false;
      let failedFileWarning = false;

      await Promise.all(
        batch.map(async (file, index) => {
          try {
            const metadata = getMetadata(file.name);

            if (metadata) {
              const updatedFile = await addMetadata(
                file,
                metadata.title,
                metadata.description,
                metadata.keywords.join(", "),
                totalProcessedFiles + index + 1,
                totalFiles
              );
              if (!updatedFile) {
                throw new Error("Error adding metadata to file");
              }
              const fileBuffer = await updatedFile.arrayBuffer();
              currentZipFiles[file.name] = new Uint8Array(fileBuffer);
              atLeastOneFileZipped = true;
              processedFiles++;
              setProgress(
                Math.floor(
                  ((totalProcessedFiles + processedFiles) / totalFiles) * 100
                )
              );
            } else if (!missingFileWarning) {
              missingFileWarning = true;
              setError(t("export.skippingMissingFiles"));
            }
          } catch (err) {
            atLeastOneFileError = true;
            if (!failedFileWarning) {
              failedFileWarning = true;
              setError(t("export.skippingFailedFiles"));
            }
          }
        })
      );

      for (const key in currentZipFiles) {
        zipFiles[key] = currentZipFiles[key];
      }

      if (atLeastOneFileZipped) {
        atLeastOneFileZippedTotal = true;
      }

      setProgress(
        Math.floor(((totalProcessedFiles + batchSize) / totalFiles) * 100)
      );

      zipFileCount += batchSize;

      if (!atLeastOneFileZipped) {
        if (atLeastOneFileError) {
          setError(t("export.processingError"));
        } else {
          setError(t("export.noFiles"));
        }
      } else if (batchPhotos && (lastBatch || zipFileCount >= zipBatchSize)) {
        zip(zipFiles, {}, (err, zippedData) => {
          if (err) {
            setError(t("export.processingError"));
          } else {
            const blob = new Blob([zippedData], { type: "application/zip" });
            saveAs(
              blob,
              `metadata_${albumName}_${
                currentZipBatchIndex + 1
              }_of_${totalZipBatches}.zip`
            );
          }
        });

        zipFiles = {};
      }
    }

    if (!batchPhotos && atLeastOneFileZippedTotal) {
      zip(zipFiles, {}, (err, zippedData) => {
        if (err) {
          setError(t("export.processingError"));
        } else {
          const blob = new Blob([zippedData], { type: "application/zip" });
          saveAs(blob, `metadata_${albumName}.zip`);
        }
      });
    }

    setLoading(false);
    setProgress(100);
  }, [files, t, getMetadata, albumName, addMetadata, batchPhotos]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/webp, video/mp4, video/quicktime",
    multiple: true,
  });

  const handleCloseError = () => {
    setError("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Fragment>
        <ModalItem
          title={
            <>
              {t("export.files")}
              <Tooltip title={t("export.explanation")} arrow>
                <IconButton size="small" sx={{ ml: 1 }}>
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    checked={batchPhotos}
                    onChange={() => setBatchPhotos(!batchPhotos)}
                    color="primary"
                    sx={{
                      transform: "scale(0.875)",
                    }}
                  />
                }
                label={t("export.batchPhotos")}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={reduceFileSize}
                    onChange={() => setReduceFileSize(!reduceFileSize)}
                    color="primary"
                    sx={{
                      transform: "scale(0.875)",
                    }}
                  />
                }
                label={t("export.reduceFileSize")}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                  },
                }}
              />
            </>
          }
          primaryContent={t("export.selectPhotos")}
          dark={true}
        >
          {!isMobile && (
            <div {...getRootProps({ className: "dropzone" })}>
              <input
                {...getInputProps({
                  multiple: true,
                  accept:
                    "image/jpeg, image/png, image/webp, video/mp4, video/quicktime",
                })}
              />
              <Tooltip title={t("export.explanationFiles")} arrow>
                <Button
                  variant="contained"
                  endIcon={<UploadFileIcon />}
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                  disabled={files.length > 0}
                >
                  {files.length === 0
                    ? t("export.uploadPhotos")
                    : `${files.length} ${t("export.filesUploaded")}`}
                </Button>
              </Tooltip>
            </div>
          )}
        </ModalItem>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
            mt: "3rem",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            endIcon={
              loading || albumLoading || uploading ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            sx={{
              fontWeight: "bold",
            }}
            onClick={processFilesInBatches}
            disabled={
              loading ||
              albumLoading ||
              uploading ||
              isMobile ||
              files.length === 0
            }
          >
            {uploading
              ? t("export.compressingFiles")
              : loading
              ? `${progress}%`
              : !albumLoading
              ? t("export.downloadFiles")
              : ""}
          </Button>
        </Box>
        {isMobile && (
          <Typography variant="body1" color="#FCFCFC" textAlign={"center"}>
            {t("export.noMobile")}
          </Typography>
        )}
      </Fragment>
      {loading && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ width: "100%", mb: "1rem" }}
        />
      )}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            width: "80%",
          }}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{
              width: "100%",
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default DownloadFiles;
