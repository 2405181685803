import { Fragment, useEffect, useState, useContext, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useParams, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Plans from "./plans";
import AutoRefill from "./AutoRefill";
import ModalComponent from "./ModalComponent";
import Login from "./login";
import { SessionContext } from "../context/SessionContext";
import MyAccount from "./MyAccount";
import Download from "./Download";
import { useLocation } from "react-router-dom";
import NavItem from "./navitem";
import { NavbarContext } from "../context/NavbarContext";
import DeleteAlbumModal from "./DeleteAlbumModal";
import ChangePassword from "./ChangePassword";

function Navbar({ supabase }) {
  const containerRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { albumId } = useParams();
  const handleClose = () => navbar.setOpenNavModal(false);
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const location = useLocation();

  const handleOpen = (slug) => {
    if (
      (slug === "more-photos" ||
        slug === "more-photos-blocked" ||
        slug === "auto-refill") &&
      !session?.user
    ) {
      slug = "login";
    }

    if (slug === "login" && session?.stashedUser) {
      session?.loadUserFromStash();
    } else {
      navbar.setSelectedNavItem(slug);
      navbar.setOpenNavModal(true);
      setDrawerOpen(false);
    }
  };

  const showNavItemButton = (navItem) => {
    if (navItem.show.includes(0) && navItem.show.includes(1)) {
      return true;
    }
    if (navItem.show.includes(0)) {
      if (session?.user === null) {
        return true;
      }
    } else if (navItem.show.includes(1)) {
      if (session?.user) {
        return true;
      }
    } else if (navItem.show.includes(2)) {
      if (
        session?.user &&
        session?.user?.id === process.env.REACT_APP_ADMIN_UID
      ) {
        return true;
      }
    }

    return false;
  };

  const getModalContent = () => {
    if (navbar.selectedNavItem === "more-photos") {
      return <Plans />;
    } else if (navbar.selectedNavItem === "more-photos-blocked") {
      return <Plans blocked={true} />;
    } else if (navbar.selectedNavItem === "auto-refill") {
      return <AutoRefill supabase={supabase} handleClose={handleClose} />;
    } else if (navbar.selectedNavItem === "login") {
      return <Login session={session} navbar={navbar} />;
    } else if (navbar.selectedNavItem === "my-account") {
      return (
        <MyAccount
          supabase={supabase}
          session={session}
          handleClose={handleClose}
          navigate={navigate}
          handleOpen={handleOpen}
        />
      );
    } else if (navbar.selectedNavItem === "credits") {
      return (
        <MyAccount
          supabase={supabase}
          session={session}
          handleClose={handleClose}
          navigate={navigate}
          handleOpen={handleOpen}
          defaultCategory={1}
        />
      );
    } else if (navbar.selectedNavItem === "download") {
      return (
        <Download
          supabase={supabase}
          session={session}
          albumIds={albumId ? [albumId] : navbar.openNavModal}
        />
      );
    } else if (navbar.selectedNavItem === "album-delete") {
      return (
        <DeleteAlbumModal
          supabase={supabase}
          session={session}
          handleClose={handleClose}
          albumId={albumId}
          albums={navbar.openNavModal}
        />
      );
    } else if (navbar.selectedNavItem === "album-delete-logout") {
      return (
        <DeleteAlbumModal
          supabase={supabase}
          session={session}
          handleClose={handleClose}
          albumId={albumId}
          albums={navbar.openNavModal}
          logout={true}
        />
      );
    } else if (navbar.selectedNavItem === "change-password") {
      return <ChangePassword session={session} navbar={navbar} />;
    } else {
      return <h1>{navbar.selectedNavItem}</h1>;
    }
  };

  let spacing = 1.1;

  const drawer = (
    <Fragment>
      {navbar.navItems.map(
        (navItem, index) =>
          showNavItemButton(navItem) && (
            <NavItem
              item={navItem}
              handleOpen={handleOpen}
              spacing={spacing}
              key={index}
            />
          )
      )}
    </Fragment>
  );

  useEffect(() => {
    containerRef.current =
      window !== undefined ? window.document.body : undefined;
  }, []);

  useEffect(() => {
    if (location.state?.closeDrawer) {
      setDrawerOpen(false);
    }
  }, [location.state]);

  useEffect(() => {
    if (session?.openWindow) {
      handleOpen(session?.openWindow);
      session?.setOpenWindow(null);
    }
  }, [session]);

  return (
    navbar?.isVisible && (
      <Fragment>
        <ModalComponent
          open={navbar.openNavModal}
          handleClose={handleClose}
          dark={
            navbar.selectedNavItem === "download"
              ? true
              : navbar.selectedNavItem === "album-delete" ||
                navbar.selectedNavItem === "album-delete-logout"
              ? true
              : false
          }
          pricing={
            session?.user &&
            (navbar.selectedNavItem === "more-photos" ||
              navbar.selectedNavItem === "more-photos-blocked")
              ? true
              : false
          }
        >
          {getModalContent()}
        </ModalComponent>

        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: "#121216",
          }}
        >
          <Box
            sx={{
              px: { xs: 0, md: 2, lg: 5 },
              py: `${spacing / 5}rem`,
            }}
          >
            <Drawer
              variant="temporary"
              container={containerRef.current}
              open={drawerOpen}
              onClose={() => setDrawerOpen((prev) => !prev)}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: "block",
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  flexGrow: 0,
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  mt: 5,
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => setDrawerOpen((prev) => !prev)}
                >
                  <CloseIcon />
                </IconButton>
                {drawer}
              </Box>
            </Drawer>

            <Container maxWidth="none">
              <Toolbar disableGutters>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      sx={{ mr: 2, display: { xs: "block", lg: "none" } }}
                      onClick={() => setDrawerOpen((prev) => !prev)}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={
                        session?.user
                          ? () => navigate("/upload")
                          : () => {
                              navigate("/");
                              window.scrollTo(0, 0);
                            }
                      }
                    >
                      <img
                        src={`/assets/logo.png`}
                        alt=""
                        style={{
                          width: "10rem",
                          marginRight: "5rem",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 0,
                      gap: "0.1rem",
                      alignItems: "center",
                      display: { xs: "none", lg: "block" },
                    }}
                  >
                    {drawer}
                  </Box>
                </Box>
              </Toolbar>
            </Container>
          </Box>
        </AppBar>
      </Fragment>
    )
  );
}
export default Navbar;
