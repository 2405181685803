import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation();
  const faqs = [
    {
      topic: "🏷️ Uploading and Tagging",
      questions: [
        {
          question: t("landing.question13"),
          answer: t("landing.answer13"),
        },
        {
          question: t("landing.question2"),
          answer: t("landing.answer2"),
        },
        {
          question: t("landing.question3"),
          answer: t("landing.answer3"),
        },
        {
          question: t("landing.question4"),
          answer: t("landing.answer4"),
        },
        {
          question: t("landing.question19"),
          answer: t("landing.answer19"),
        },
        {
          question: t("landing.question20"),
          answer: t("landing.answer20"),
        },
        {
          question: t("landing.question21"),
          answer: t("landing.answer21"),
        },
        {
          question: t("landing.question22"),
          answer: t("landing.answer22"),
        },
        {
          question: t("landing.question23"),
          answer: t("landing.answer23"),
        },
        {
          question: t("landing.question24"),
          answer: t("landing.answer24"),
        },
        {
          question: t("landing.question12"),
          answer: t("landing.answer12"),
        },
        {
          question: t("landing.question0"),
          answer: t("landing.answer0"),
        },
        {
          question: t("landing.question1"),
          answer: t("landing.answer1"),
        },
        {
          question: t("landing.question5"),
          answer: t("landing.answer5"),
        },
        {
          question: t("landing.question6"),
          answer: t("landing.answer6"),
        },
        {
          question: t("landing.question7"),
          answer: t("landing.answer7"),
        },
      ],
    },
    {
      topic: "💎 Subscription and Billing",
      questions: [
        {
          question: t("landing.question14"),
          answer: t("landing.answer14"),
        },
        {
          question: t("landing.question15"),
          answer: t("landing.answer15"),
        },
        {
          question: t("landing.question25"),
          answer: t("landing.answer25"),
        },
      ],
    },
    {
      topic: "✨Data and Results",
      questions: [
        {
          question: t("landing.question8"),
          answer: t("landing.answer8"),
        },
        {
          question: t("landing.question9"),
          answer: t("landing.answer9"),
        },
        {
          question: t("landing.question10"),
          answer: t("landing.answer10"),
        },
        {
          question: t("landing.question11"),
          answer: t("landing.answer11"),
        },
      ],
    },
    {
      topic: "📧 Technical Support",
      questions: [
        {
          question: t("landing.question16"),
          answer: t("landing.answer16"),
        },
        {
          question: t("landing.question17"),
          answer: t("landing.answer17"),
        },
        {
          question: t("landing.question18"),
          answer: t("landing.answer18"),
        },
      ],
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        gap: "2rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: { xs: "90%", md: "60%" },
        }}
      >
        {faqs.map((topic, indexa) =>
          topic?.questions?.map((faq, index) => (
            <Fragment key={(indexa + 1) * index}>
              <Accordion
                key={index}
                sx={{
                  borderRadius: "1rem",
                  color: "#111827",
                  backgroundColor: "#fff",
                }}
                defaultExpanded
                disableGutters
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor="black" />}
                >
                  <Typography variant="h5" fontWeight={"bold"}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography fontWeight={"normal"}>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </Fragment>
          ))
        )}
      </Box>
    </Box>
  );
};
export default Help;
