import { createContext, useState, useContext } from "react";
import { SessionContext } from "./SessionContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addCommas } from "../utils";

export const NavbarContext = createContext({});

const NavbarContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  const [openNavModal, setOpenNavModal] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [extraContent, setExtraContent] = useState(null);

  const navItems = [
    {
      title: "Admin",
      slug: "panel",
      color: "secondary",
      onClick: () => navigate("/panel", { state: { closeDrawer: true } }),
      show: [2],
    },
    {
      title: t("landing.upload"),
      slug: "upload",
      onClick: () => navigate("/upload", { state: { closeDrawer: true } }),
      color: "secondary",
      show: [1],
    },
    {
      title: t("landing.albums"),
      slug: "my-albums",
      onClick: () => navigate("/albums", { state: { closeDrawer: true } }),
      color: "secondary",
      show: [1],
    },
    {
      title: t("landing.pricing"),
      slug: "pricing",
      color: "secondary",
      onClick: () =>
        navigate("/pricing", {
          state: { scrollToPricing: true, closeDrawer: true },
        }),
      show: [0],
    },
    {
      title: "FAQ",
      slug: "help",
      color: "secondary",
      onClick: () =>
        navigate("/faq", { state: { scrollToHelp: true, closeDrawer: true } }),
      show: [0],
    },
    {
      title: "API",
      slug: "api",
      onClick: () => navigate("/api", { state: { closeDrawer: true } }),
      color: "secondary",
      show: [0, 1],
    },
    {
      title: t("landing.plugin"),
      slug: "lightroom-plugin",
      onClick: () =>
        navigate("/lightroom-plugin", { state: { closeDrawer: true } }),
      color: "secondary",
      show: [0, 1],
    },
    {
      title: t("landing.ecommerce"),
      slug: "ecommerce-product-tagging",
      onClick: () =>
        navigate("/ecommerce-product-tagging", {
          state: { closeDrawer: true },
        }),
      color: "secondary",
      show: [0],
    },
    {
      title: t("landing.affiliate"),
      slug: "affiliates",
      color: "secondary",
      onClick: () => navigate("/affiliates", { state: { closeDrawer: true } }),
      show: [0],
    },
    {
      title: t("landing.blog"),
      slug: "blog",
      onClick: () => navigate("/blog", { state: { closeDrawer: true } }),
      color: "secondary",
      show: [0],
    },
    {
      title: t("landing.trends"),
      slug: "trends",
      color: "secondary",
      onClick: () => navigate("/trends", { state: { closeDrawer: true } }),
      show: [0, 1],
    },
    {
      title: t("landing.login"),
      slug: "login",
      variant: "outlined",
      color: "secondary",
      className: "gradient-bg",
      show: [0],
    },
    {
      title: t("landing.account"),
      slug: "my-account",
      color: "secondary",
      chipStyle: {
        borderRadius: "1rem",
        padding: "0.2rem 0.5rem",
        position: "absolute",
        right: "-1rem",
        top: "-0.5rem",
        width: "30%",
        fontWeight: "bold",
        border: "1px solid rgba(58, 208, 255, 0.5)",
      },
      show: [1],
    },
    {
      title:
        session?.bundleCreditsAllowed === undefined ||
        session?.bundleCreditsUsed === undefined ||
        window.location.pathname.includes("/result")
          ? t("account.usage")
          : `💎 ${addCommas(
              Math.max(
                0,
                Number(
                  session?.subStatus?.[session?.subStatus?.length - 1]?.plan
                    ?.metadata?.credits || 0
                ) +
                  (session?.bundleCreditsAllowed || 0) -
                  (session?.planCreditsUsed || 0) -
                  (session?.bundleCreditsUsed || 0)
              )
            )}`,
      slug: "credits",
      color: "secondary",
      chipStyle: {
        borderRadius: "1rem",
        padding: "0.2rem 0.5rem",
        position: "absolute",
        right: "-1rem",
        top: "-0.5rem",
        width: "30%",
        fontWeight: "bold",
        border: "1px solid rgba(58, 208, 255, 0.5)",
      },
      show: [1],
    },
    {
      title: t("landing.morePhotos"),
      slug: "more-photos",
      variant: "outlined",
      color: "secondary",
      className: "gradient-bg",
      show: [1],
    },
    {
      title: t("change.changePassword"),
      slug: "change-password",
      variant: "outlined",
      color: "secondary",
      className: "gradient-bg",
      show: [],
    },
  ];

  return (
    <NavbarContext.Provider
      value={{
        openNavModal,
        setOpenNavModal,
        selectedNavItem,
        setSelectedNavItem,
        navItems,
        setIsVisible,
        isVisible,
        extraContent,
        setExtraContent,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContextProvider;
