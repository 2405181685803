import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function ECommPlatform({ textPrefix }) {
  const { t } = useTranslation();

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": window.location.href,
    },
    headline: `PhotoTag.ai - ${t(`${textPrefix}.headingSubtext`)}`,
    description: t(`${textPrefix}.headingDescription`),
    image:
      "https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg",
    publisher: {
      "@type": "Organization",
      name: "PhotoTag.ai",
      logo: {
        "@type": "ImageObject",
        url: "https://phototag.ai/assets/logo.png",
      },
    },
  };

  return (
    <Fragment>
      <Helmet>
        <title>PhotoTag.ai - {t(`${textPrefix}.headingSubtext`)}</title>
        <meta
          name="description"
          content={t(`${textPrefix}.headingDescription`)}
        />
        <meta
          property="og:title"
          content={"PhotoTag.ai - " + t(`${textPrefix}.headingSubtext`)}
        />
        <meta
          property="og:description"
          content={t(`${textPrefix}.headingDescription`)}
        />
        <meta
          property="og:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="PhotoTag.ai" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={"PhotoTag.ai - " + t(`${textPrefix}.headingSubtext`)}
        />
        <meta
          name="twitter:description"
          content={t(`${textPrefix}.headingDescription`)}
        />
        <meta
          name="twitter:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box
        sx={{
          overflowY: "auto",
          paddingTop: { xs: "4rem", md: "8rem" },
          backgroundImage: {
            lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
            xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8&auto=format&fit=crop&w=1932&q=80)",
          },
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
            padding: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              padding: "3rem",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
              borderRadius: "12px",
              textAlign: "center",
              width: "100%",
              maxWidth: "80%",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{
                color: "#000",
                textAlign: "center",
                fontSize: { xs: "2.5rem", md: "3.2rem" },
              }}
            >
              {t(`${textPrefix}.header`)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#000",
                maxWidth: "90%",
                textAlign: "center",
                mb: 2,
                fontSize: { xs: "1rem", md: "1.2rem" },
              }}
            >
              <a
                href="https://www.shoptag.ai"
                style={{
                  fontWeight: "bold",
                  color: "inherit",
                  textDecoration: "underline",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`${textPrefix}.link`)}
              </a>{" "}
              <span style={{ fontWeight: "bold" }}>
                {t(`${textPrefix}.subtext1`)}
              </span>{" "}
              {t(`${textPrefix}.subtext2`)}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="https://www.shoptag.ai"
              sx={{
                padding: "16px 32px",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {t(`${textPrefix}.join`)}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "1rem",
            maxWidth: "800px",
            margin: "0 auto",
            mb: "2rem",
          }}
        >
          <Typography
            variant="h4"
            sx={{ marginBottom: "1rem", fontWeight: "bold" }}
          >
            FAQ
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq1`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq1Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq2`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq2Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq3`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq3Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq4`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq4Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq5`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq5Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq6`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq6Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq7`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq7Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq8`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq8Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq9`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq9Answer`)}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t(`${textPrefix}.faq10`)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            {t(`${textPrefix}.faq10Answer`)}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
}

export default ECommPlatform;
