import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Affiliates() {
  const { t } = useTranslation();

  const openAffiliateUrl = () => {
    const url = process.env.REACT_APP_AFFILIATE_URL;
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      <Helmet>
        <title>PhotoTag.ai - {t("affiliatesHelmet.headingSubtext")}</title>
        <meta
          name="description"
          content={t("affiliatesHelmet.headingDescription")}
        />
        <meta
          property="og:title"
          content={"PhotoTag.ai - " + t("affiliatesHelmet.headingSubtext")}
        />
        <meta
          property="og:description"
          content={t("affiliatesHelmet.headingDescription")}
        />
        <meta
          property="og:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="PhotoTag.ai" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={"PhotoTag.ai - " + t("affiliatesHelmet.headingSubtext")}
        />
        <meta
          name="twitter:description"
          content={t("affiliatesHelmet.headingDescription")}
        />
        <meta
          name="twitter:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              },
              "headline": "PhotoTag.ai - ${t(
                "affiliatesHelmet.headingSubtext"
              )}",
              "description": "${t("affiliatesHelmet.headingDescription")}",
              "image": "https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg",
              "publisher": {
                "@type": "Organization",
                "name": "PhotoTag.ai",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://phototag.ai/assets/logo.png"
                }
              }
            }
          `}
        </script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box
        sx={{
          backgroundImage: {
            lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
            xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8&auto=format&fit=crop&w=1932&q=80)",
          },
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            height: "auto",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
            maxWidth: "90vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              padding: "3rem",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
              borderRadius: "12px",
              textAlign: "center",
              width: "100%",
              maxWidth: "80%",
              maxHeight: "60vh",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{
                color: "#000",
                textAlign: "center",
                fontSize: { xs: "2.5rem", md: "3.2rem" },
              }}
            >
              {t("affiliates.header")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#000",
                maxWidth: "90%",
                textAlign: "center",
                mb: 2,
                fontSize: { xs: "1rem", md: "1.2rem" },
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("affiliates.subtext1")}
              </span>{" "}
              {t("affiliates.subtext2")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={openAffiliateUrl}
              sx={{
                padding: "16px 32px",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {t("affiliates.join")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Affiliates;
