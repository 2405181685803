import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TopControls from "./TopControls";
import TextEdit from "./TextEdit";
import NewKeywordsControls from "./NewKeywordsControls";
import KeywordsEdit from "./KeywordsEdit";
import Typography from "@mui/material/Typography";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { PauseCircleFilled } from "@mui/icons-material";
import ReplayIcon from "@mui/icons-material/Replay";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const EditorControls = ({
  supabase,
  links,
  selectedPhoto,
  handleSelect,
  predictions,
  setPredictions,
  generating,
  setGenerating,
  setUnsavedPredictions,
  session,
  albumId,
  setSnackBarMessage,
  setOpenSnackBar,
  albumSettings,
  stucked,
  handleResumeGenerating,
  handlePauseGenerating,
}) => {
  const { t } = useTranslation();
  const [keywordsInput, setKeywordsInput] = useState("");
  const [bottomButtonType, setBottomButtonType] = useState("pause");
  const [working, setWorking] = useState(false);
  const [generatingIndex, setGeneratingIndex] = useState(null);
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [titleWordCount, setTitleWordCount] = useState(0);
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [descriptionWordCount, setDescriptionWordCount] = useState(0);

  useEffect(() => {
    if (predictions?.[selectedPhoto]?.data?.[0]?.[0]) {
      setTitleCharCount(predictions?.[selectedPhoto]?.data?.[0]?.[0].length);
      setTitleWordCount(
        predictions?.[selectedPhoto]?.data?.[0]?.[0].split(/\s/).length
      );
    }
    if (predictions?.[selectedPhoto]?.data?.[0]?.[1]) {
      setDescriptionCharCount(
        predictions?.[selectedPhoto]?.data?.[0]?.[1].length
      );
      setDescriptionWordCount(
        predictions?.[selectedPhoto]?.data?.[0]?.[1].split(/\s/).length
      );
    }
  }, [predictions, selectedPhoto]);

  const handleEditTitle = (e, value, i) => {
    if (e) {
      e.preventDefault();
    }

    value = value.replace(/[\n\t\r]/g, "");

    let copia = [...predictions];
    copia[i].data[0][0] = value;

    setPredictions(copia);
    setUnsavedPredictions(copia);
  };

  const limitKeywords = (pred) => {
    let copy = [...pred];
    for (let i = 0; i < pred.length; i++) {
      copy[i].data[1] =
        typeof albumSettings?.maxKeywords === "number"
          ? pred?.[i]?.data?.[1]?.slice(0, albumSettings?.maxKeywords)
          : pred?.[i]?.data?.[1];
    }
    return copy;
  };

  const handleEditDescription = (e, value, i) => {
    if (e) {
      e.preventDefault();
    }

    value = value.replace(/[\n\t\r]/g, "");

    let copia = [...predictions];
    copia[i].data[0][1] = value;

    setPredictions(copia);
    setUnsavedPredictions(copia);
  };

  const handleEditKeywords = (refkey, i, options, newKeywords) => {
    options.action === "add" && refkey.preventDefault();
    let copia = [...predictions];
    let convertedArray = [];
    if (options.action === "order") {
      for (let i = 0; i < refkey.length; i++) {
        convertedArray.push(refkey[i].name);
      }
      copia[i].data[1] = convertedArray;
      setPredictions(limitKeywords(copia));
      setUnsavedPredictions(limitKeywords(copia));
    } else if (options.action === "delete") {
      let filteredCopia = copia[i].data[1].filter(
        (keyword) => !refkey.includes(keyword)
      );
      copia[i].data[1] = filteredCopia;
      setPredictions(limitKeywords(copia));
      setUnsavedPredictions(limitKeywords(copia));
    } else if (options.action === "add" || options.action === "regenerate") {
      let splitWordsArr;
      if (options.action === "add") {
        splitWordsArr = keywordsInput.split(",");
      } else if ((options.action = "regenerate")) {
        splitWordsArr = newKeywords.split(",");
      }
      let filteredArr = splitWordsArr.filter((str) => !/^[\s\W]*$/.test(str));
      let trimmedArr = filteredArr.map((str) => str.trim());
      copia[i].data[1] = [...new Set([...trimmedArr, ...copia[i]?.data?.[1]])];
      setKeywordsInput("");
      setPredictions(limitKeywords(copia));
      setUnsavedPredictions(limitKeywords(copia));
    }
  };

  const handleGenerateAgain = async (e) => {
    e.preventDefault();
    setGenerating((prev) => [...prev, selectedPhoto]);
    try {
      if (!stucked && predictions?.length >= links?.length) {
        const {
          data: { session: supabaseSession },
        } = await supabase.auth.getSession();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/regenerate-all`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${supabaseSession.access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_data: {
                album_id: albumId,
                sub_id:
                  session?.subStatus?.[session?.subStatus?.length - 1]?.id ||
                  "no_sub",
              },
              photo: [links?.[selectedPhoto]],
            }),
          }
        );
        const json = await response.json();

        const { data, error } = json;
        if (!error) {
          handleEditKeywords(
            null,
            selectedPhoto,
            { action: "regenerate" },
            data?.[0]?.data?.[1]?.join(", ")?.toString()
          );
          handleEditTitle(null, data?.[0]?.data?.[0]?.[0], selectedPhoto);
          handleEditDescription(null, data?.[0]?.data?.[0]?.[1], selectedPhoto);
          setGenerating((prev) => prev.filter((i) => i !== selectedPhoto));
        } else {
          setSnackBarMessage("⚠️ " + error);
          setOpenSnackBar(true);
        }
      }
    } catch (error) {
      setSnackBarMessage("⚙️ " + t("editor.serverError"));
      setOpenSnackBar(true);
    }
    setGenerating((prev) => prev.filter((i) => i !== selectedPhoto));
  };

  useEffect(() => {
    setPredictions(limitKeywords(predictions));
  }, [albumSettings]);

  useEffect(() => {
    if (!working) {
      if (stucked) {
        setBottomButtonType("resume");
      } else if (predictions.length < links?.length) {
        setBottomButtonType("pause");
      } else {
        setBottomButtonType("again");
      }
    }
  }, [stucked, predictions, links, working]);

  useEffect(() => {
    const index = predictions.length;
    if (!stucked && index < links?.length) {
      if (generatingIndex !== index) {
        setGenerating((prev) => prev.filter((i) => i !== generatingIndex));
        setGenerating((prev) => [...prev, index]);
        setGeneratingIndex(index);
      }
    } else if (generatingIndex) {
      setGenerating((prev) => prev.filter((i) => i !== generatingIndex));
      setGeneratingIndex(null);
    }
  }, [predictions, stucked, generatingIndex, links]);

  useEffect(() => {
    if (working) {
      setTimeout(() => {
        setWorking(false);
      }, 10000);
    }
  }, [working]);

  return (
    <Box
      sx={{
        backgroundColor: "#121216",
        borderRadius: "1rem",
        color: "#dcdcdc",
        padding: { xs: "0.2rem 1rem", md: "0.2rem 2rem" },
        height: { xs: "100%", md: "calc(100vh - 12rem)" },
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flex: 1,
          height: "95%",
        }}
      >
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              mt: 3,
            }}
          >
            <SubtitlesIcon />
            <Typography
              variant="h5"
              color="initial"
              sx={{
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {t("editor.editText")}
            </Typography>
          </Box>
          {links?.length !== 1 && (
            <TopControls
              selectedPhoto={selectedPhoto}
              links={links}
              handleSelect={handleSelect}
            />
          )}
        </Box>

        <TextEdit
          title={`${t("editor.title")} ✒️ (${t("editor.charCount", {
            count: titleCharCount,
          })}, ${t("editor.wordCount", { count: titleWordCount })})`}
          style={{
            fontWeight: "normal",
            color: "#dcdcdc",
            fontFamily: "Verdana",
          }}
          maxRows={3}
          value={
            predictions?.[selectedPhoto]?.data?.[0]?.[0]
              ? predictions?.[selectedPhoto]?.data?.[0]?.[0]
              : ""
          }
          handleChanges={(e) =>
            handleEditTitle(e, e.target.value, selectedPhoto)
          }
          disabled={
            predictions.length - 1 < selectedPhoto
              ? true
              : false || generating?.includes(selectedPhoto)
          }
        />

        <TextEdit
          title={`${t("editor.description")} ✒️ (${t("editor.charCount", {
            count: descriptionCharCount,
          })}, ${t("editor.wordCount", { count: descriptionWordCount })})`}
          style={{
            fontWeight: "normal",
            color: "#dcdcdc",
            fontFamily: "Verdana",
          }}
          maxRows={4}
          value={
            predictions?.[selectedPhoto]?.data?.[0]?.[1]
              ? predictions?.[selectedPhoto]?.data?.[0]?.[1]
              : ""
          }
          handleChanges={(e) =>
            handleEditDescription(e, e.target.value, selectedPhoto)
          }
          disabled={
            predictions.length - 1 < selectedPhoto
              ? true
              : false || generating?.includes(selectedPhoto)
          }
        />
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Button
            variant="outlined"
            color="info"
            sx={{
              fontWeight: "bold",
              fontSize: "0.7rem",
              width: "50%",
            }}
            disabled={!predictions?.[selectedPhoto]?.data?.[0]?.[0]}
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              navigator.clipboard.writeText(
                predictions?.[selectedPhoto]?.data?.[0]?.[0]
              );
            }}
          >
            {t("editor.copyTitle")}
          </Button>
          <Button
            variant="outlined"
            color="info"
            sx={{
              fontWeight: "bold",
              fontSize: "0.7rem",
              width: "50%",
            }}
            disabled={!predictions?.[selectedPhoto]?.data?.[0]?.[1]}
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              navigator.clipboard.writeText(
                predictions?.[selectedPhoto]?.data?.[0]?.[1]
              );
            }}
          >
            {t("editor.copyDescription")}
          </Button>
        </Box>

        <NewKeywordsControls
          supabase={supabase}
          predictions={predictions}
          links={links}
          selectedPhoto={selectedPhoto}
          handleEditKeywords={handleEditKeywords}
          setKeywordsInput={setKeywordsInput}
          generating={generating}
          setGenerating={setGenerating}
          session={session}
          albumId={albumId}
          setSnackBarMessage={setSnackBarMessage}
          setOpenSnackBar={setOpenSnackBar}
          limitKeywords={limitKeywords}
        />

        <KeywordsEdit
          selectedPhoto={selectedPhoto}
          predictions={predictions}
          handleEditKeywords={handleEditKeywords}
          keywordsInput={keywordsInput}
          setKeywordsInput={setKeywordsInput}
          generating={generating}
          setGenerating={setGenerating}
          albumSettings={albumSettings}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "sticky",
            bottom: "1rem",
            zIndex: 5,
            boxShadow:
              "inset  0px 1.5rem 0px 2rem #121216, 0px 1.5rem 2rem 3rem #121216",
            mt: "auto",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            startIcon={
              bottomButtonType === "resume" ? (
                <ReplayIcon />
              ) : bottomButtonType === "pause" ? (
                <PauseCircleFilled />
              ) : (
                <AutoAwesomeIcon />
              )
            }
            endIcon={
              bottomButtonType === "resume" ? (
                <ReplayIcon />
              ) : bottomButtonType === "pause" ? (
                <PauseCircleFilled />
              ) : (
                <AutoAwesomeIcon />
              )
            }
            sx={{
              fontWeight: "bold",
            }}
            disabled={
              (predictions.length >= links?.length &&
                generating?.includes(selectedPhoto)) ||
              working
            }
            onClick={(e) => {
              bottomButtonType === "resume"
                ? handleResumeGenerating() && setWorking(true)
                : bottomButtonType === "pause"
                ? handlePauseGenerating() && setWorking(true)
                : handleGenerateAgain(e);
            }}
          >
            {bottomButtonType === "resume"
              ? t("editor.resume")
              : bottomButtonType === "pause"
              ? t("editor.pause")
              : t("editor.generateEverything")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditorControls;
